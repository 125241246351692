<template>
  <section>
    <template v-if="!showSuccess">
      <b-card body-class="pb-0" class="mb-0 d-flex">
        <b-row>
          <b-col cols="12" md="8">
            <h2>{{ negotiatedRate.companyName }}</h2>
          </b-col>
          <b-col cols="12" md="4">
            <b-button class="btn-tour-next float-right" size="sm" type="button" @click="companyImport = true">
              <span class="mr-25 align-middle">{{ $t('action.import') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-card>
      <app-data-table
        ref="contract-price-management-company-airports"
        :actions-table="{
          showEdit: $can('NEGOTIATED_RATE_EDIT'),
        }"
        manual-pagination
        :bottom-table="{ totalCount: pagination.totalItems }"
        :busy="companyAirportsLoading"
        :fields="companyAirportsFields"
        :items="companyAirports"
        :small="$store.getters['app/lgAndDown']"
        :page="paginate.page"
        :per-page="paginate.numberOfItemsPerPage"
        :top-table="{
          showPerPage: $can('NEGOTIATED_RATE_VIEW'),
          showSearch: $can('NEGOTIATED_RATE_VIEW'),
          disabled: companyAirportsLoading,
          searchFilterOptions,
        }"
        table-name="contract-price-management-company-airports"
        @edit="editCompanyAirportDiscount($event.item)"
        @pagination=";(paginate.page = $event), fetchFuelProductFuelVariantNegociatedRateAirport()"
        @table-select-per-page=";(paginate.numberOfItemsPerPage = $event), fetchFuelProductFuelVariantNegociatedRateAirport()"
        @table-search-text=";(search.icao = $event), fetchFuelProductFuelVariantNegociatedRateAirport()"
      >
        <template #cell(description)="{ item }">
          <span>{{ item.airportName }}</span>
        </template>

        <template #cell(airportCountry)="{ item }">
          <span>{{ item.airportCountry.name }}</span>
        </template>
        <template #cell(discountValue)="{ item }">
          <span v-if="item.discountType === 'currency'">{{ item.discountValue | priceFormat }}</span>
          <span v-if="item.discountType === 'percent'">{{ item.discountValue | percentFormat }}</span>
        </template>
      </app-data-table>
      <b-card class="mb-0">
        <footer-form-service previous @click:previous="$emit('show-company-airports-price-management', false)" />
      </b-card>
    </template>
    <CompanyAirportDiscountForm
      :company-airport="selectedCompanyAirport"
      :company-airport-discount-edition="companyAirportDiscountEdition"
      :company-airport-discount-loading="companyAirportsLoading"
      @companyAirportDiscountUpdated="patchFuelProductFuelVariantNegociatedRateAirport($event)"
      @editionUpdated="companyAirportDiscountEdition = $event"
    />
    <CompanyAirportImportForm
      :company-import="companyImport"
      :negotiated-rate="negotiatedRate"
      @editionUpdated="companyImport = $event"
      @refetch="refetchAndCloseImport()"
    />
  </section>
</template>

<script>
import {
  fetchFuelProductFuelVariantNegociatedRateAirportRequest,
  patchFuelProductFuelVariantNegociatedRateAirportRequest,
} from '@/request/globalApi/requests/fuelProductRequests'

import AppDataTable from '@/components/AppDataTable.vue'
import FooterFormService from '@/views/product/component/FooterFormService.vue'
import CompanyAirportDiscountForm from '@/views/product/component/CompanyAirportDiscountForm.vue'
import CompanyAirportImportForm from '@/views/product/component/CompanyAirportImportForm.vue'

export default {
  name: 'ContractPriceManagementCompanyAirports',
  components: {
    AppDataTable,
    FooterFormService,
    CompanyAirportDiscountForm,
    CompanyAirportImportForm,
  },
  props: {
    companyName: {
      type: String,
      default: '',
    },
    negotiatedRate: {
      type: Object,
      default: null,
    },
    tableClass: {
      type: String,
      default: '',
    },
    primaryKey: {
      type: String,
      default: 'id',
    },
    productId: {
      type: Number,
      default: null,
    },
    variantId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      showSuccess: false,
      companyAirportsLoading: false,
      companyAirports: [],
      companyAirportDiscountEdition: false,
      companyImport: false,
      selectedCompanyAirport: {},
      pagination: { totalItems: 0 },
      paginate: { page: 1, numberOfItemsPerPage: 0 },
      searchFilterOptions: [{ value: 'icao', text: this.$t('airport.icao') }],
      search: {
        icao: '',
      },
    }
  },

  computed: {
    companyAirportsFields() {
      return [
        {
          key: 'description',
          label: this.$t('common.airport'),
        },
        {
          key: 'airportCountry',
          label: this.$t('common.country'),
        },
        {
          key: 'airportSupplier',
          label: this.$t('common.supplier'),
        },
        {
          key: 'discountValue',
          label: this.$t('pricing.discount_rate'),
        },
      ]
    },
  },

  mounted() {
    this.fetchFuelProductFuelVariantNegociatedRateAirport()
  },

  methods: {
    refetchAndCloseImport() {
      this.companyImport = false
      this.fetchFuelProductFuelVariantNegociatedRateAirport()
    },

    fetchFuelProductFuelVariantNegociatedRateAirport() {
      this.companyAirportsLoading = true
      this.paginate.numberOfItemsPerPage === 0 && (this.paginate.numberOfItemsPerPage = this.$store.state.appConfig.dataTable.perPage)
      fetchFuelProductFuelVariantNegociatedRateAirportRequest(this.productId, this.variantId, this.negotiatedRate.id, { ...this.paginate, ...this.search })
        .then(response => {
          this.pagination.totalItems = response.data.totalItems
          this.companyAirports = response.data.airports
        })
        .finally(() => {
          this.companyAirportsLoading = false
        })
    },
    editCompanyAirportDiscount(companyAirport) {
      this.selectedCompanyAirport = companyAirport
      this.companyAirportDiscountEdition = true
    },
    patchFuelProductFuelVariantNegociatedRateAirport(companyAirport) {
      this.companyAirportsLoading = true
      patchFuelProductFuelVariantNegociatedRateAirportRequest(this.productId, this.variantId, this.negotiatedRate.id, companyAirport.id, {
        discountValue: parseFloat(companyAirport.discountValue),
        discountType: companyAirport.discountType,
      })
        .then(() => {
          this.fetchFuelProductFuelVariantNegociatedRateAirport()
        })
        .catch(err => {
          err && (this.companyAirportsLoading = false)
        })
    },
  },
}
</script>
