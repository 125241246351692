<template>
  <div id="company-airport-import-form">
    <app-sidebar-form
      :edition="companyImport"
      :show-reset="false"
      :show-submit="false"
      :title="negotiatedRate.companyName"
      edition-mode-title="import"
      form-name="company-airport-import-form"
      @update:edition="$emit('editionUpdated', $event)"
    >
      <template #fields="data">
        <b-row>
          <b-col>
            <b-overlay :show="dropZoneLoading" class="mt-1" spinner-medium>
              <app-drop-zone
                id="upload-files"
                ref="uploadFiles"
                :options="dropzoneOptions"
                @vdropzone-queue-complete="fileUplaoded = true"
                @vdropzone-removed-file="removePlainFile($event)"
                @vdropzone-file-added="sendPlainFile($event)"
              />
            </b-overlay>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col class="mb-1" cols="12">
            <span>{{ $t('common.note') }} {{ $t('service.fuel.customer_contract.import_note') }}</span>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-button block type="button" @click="downloadCsv()">{{ $t('action.download_example') }}</b-button>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <b-button :disabled="!fileId" block class="mt-2" type="button" @click="postUploadCSV()">{{ $t('action.import') }}</b-button>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import AppSidebarForm from '@/components/AppSidebarForm.vue'
import AppDropZone from '@/components/AppDropZone.vue'
import {
  downloadFuelPriceNegotiatedRateCSV,
  postFuelProductFuelVariantNegotiatedRateUploadCSVRequest,
} from '@/request/globalApi/requests/fuelProductRequests'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'CompanyAirportImportForm',

  components: {
    AppSidebarForm,
    AppDropZone,
  },

  props: {
    negotiatedRate: {
      type: Object,
      default: null,
    },
    companyImport: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dropzoneOptions: {
        autoProcessQueue: false,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('common.csv.upload'),
        thumbnailWidth: 250,
        maxFiles: 1,
        maxFilesize: 2000000,
        headers: { 'My-Awesome-Header': 'header value' },
        acceptedFiles: '.csv',
        addRemoveLinks: true,
        clickable: true,
        uploadMultiple: false,
      },
      fileUplaoded: false,
      dropZoneLoading: false,
      fileId: null,
    }
  },

  methods: {
    postUploadCSV() {
      const payload = { temporaryFileId: this.fileId }
      postFuelProductFuelVariantNegotiatedRateUploadCSVRequest({
        productId: this.$route.params.service_id,
        variantId: this.$route.query.vid,
        contractId: this.negotiatedRate.id,
      }, payload).then(() => {
        this.$emit('refetch')
      })
    },

    downloadCsv() {
      downloadFuelPriceNegotiatedRateCSV()
    },

    removePlainFile() {
      this.fileId = null
    },

    sendPlainFile(file) {
      if (file.size > this.dropzoneOptions.maxFilesize) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('file.max_file_size_reached'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.$refs.uploadFiles.removeFile(file)
        return
      }

      // If from API, do nothing
      if (file.id) return

      // If from device
      this.dropZoneLoading = true
      this.iconUploaded = false
      const formData = new FormData()
      formData.append('temporary_file', file)

      postFileRequest(formData)
        .then(response => {
          this.fileId = response.data.id
        })
        .finally(() => {
          this.iconUploaded = true
          this.dropZoneLoading = false
        })
    },
  },
}
</script>
